import styled from '@emotion/styled';
import React from 'react';
import fonts from 'style/fonts';
import { mq } from 'style/variables';

type JaHeadingProps = {
  size?: 'xsmall' | 'small' | 'demismall' | 'middle' | 'large' | 'page' | 'pageEn';
  align?: 'center';
} & JSX.IntrinsicElements['h3'];

const sizes = {
  xsmall: { pc: 15, sp: 15 },
  demismall: {
    pc: 20,
    sp: 20,
  },
  small: { pc: 24, sp: 20 },
  middle: {
    pc: 36,
    sp: 36,
  },
  large: {
    pc: 36,
    sp: 36,
  },
  // 以下はRbrで追加
  page: {
    pc: 54,
    sp: 40,
  },
  pageEn: {
    pc: 48,
    sp: 32,
  },
};

const lineHeight = {
  xsmall: 1.48,
  small: 1.48,
  demismall: 1.48,
  middle: 1.48,
  large: 1.48,
  // 以下はRbrで追加
  page: 1.3,
  pageEn: 1.3,
};
const Container = styled.div<JaHeadingProps>`
  font-family: ${fonts.jaGothic};
  line-height: ${({ size }) => (size ? lineHeight[size] : 1.48)}em;
  font-size: ${({ size }) => (size ? sizes[size].pc : 36)}px;
  ${({ align }) => align === 'center' && 'text-align: center;'}
  letter-spacing: 0.02em;
  ${mq.onlysp} {
    > br {
      display: none;
    }
    font-size: ${({ size }) => (size ? sizes[size].sp : 36)}px;
  }

  [data-lang='en'] & {
    font-family: ${fonts.enSans};
    letter-spacing: 0em;
  }
`;

export const JaHeading: React.FC<JaHeadingProps> = props => {
  const TagName = Container.withComponent(props.size === 'small' ? 'h4' : props.size === 'middle' ? 'h3' : 'h2');
  return <TagName {...props}>{props.children}</TagName>;
};
