import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Arrow } from 'components/atoms/arrow';
import { EnHeading } from 'components/atoms/enHeading';
import { Text } from 'components/atoms/text';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import colors from 'style/colors';
import sizes from 'style/sizes';
import { baseTransitionSecond, breakpoint, contentWidthPc, mq } from 'style/variables';

type InquiryBlockProps = {
  top?: boolean;
  en?: boolean;
  isStaticSp?: boolean;
};

const INNER_PADDING__SP = 32;

const Container = styled('div')`
  overflow: hidden;
  background-color: ${colors.gray03};
  ${mq.onlypc} {
    position: relative;
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 50%;
      transform: scaleX(0);
      transform-origin: right;
      background-color: ${colors.gray02};
      opacity: 0.3;
      transition: transform ${baseTransitionSecond}s linear,
        opacity ${baseTransitionSecond / 2}s linear ${baseTransitionSecond}s;
    }
    &::before {
      left: 0;
    }
    &::after {
      left: 50%;
    }
    &.is-hover-left {
      &::before {
        transform: scaleX(1);
        transform-origin: left;
      }
    }
    &.is-hover-right {
      &::after {
        transform: scaleX(1);
        transform-origin: left;
      }
    }
  }
  ${mq.onlysp} {
    overflow: hidden;
  }
`;

const Inner = styled('div')`
  max-width: ${contentWidthPc}px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  ${mq.onlysp} {
    padding: 0 ${INNER_PADDING__SP}px;
  }
`;

const CardList = styled('ul')`
  ${mq.onlypc} {
    margin: 0 -60px;
    display: flex;
  }
  ${mq.onlysp} {
    display: grid;
    grid-template-rows: 1fr 1fr;
  }
`;

const CardListItem = styled('li')`
  ${mq.onlypc} {
    position: relative;
    display: flex;
    margin: 0 60px;
    flex: 1;
    z-index: 10;
  }
`;

const CardLinkStyle = css`
  ${mq.onlypc} {
    width: 100%;
    padding: ${sizes.margin[96]} 0;
    flex-direction: column;
    display: flex;
    transition: opacity ${baseTransitionSecond}s;
  }
  ${mq.onlysp} {
    padding: ${sizes.margin[44]} 0;
    display: block;
  }
`;

const CardLink = styled(Link)`
  ${CardLinkStyle}
  ${mq.onlypc} {
    .is-hover-right & {
      opacity: 0.2;
    }
  }
`;

const CardLinkBlank = styled('a')`
  ${CardLinkStyle}
  ${mq.onlypc} {
    .is-hover-left & {
      opacity: 0.2;
    }
  }
`;
const CardLinkInner = styled('div')`
  position: relative;
  ${mq.onlypc} {
    min-height: 132px;
  }
`;

const CardHead = styled(EnHeading)`
  position: relative;
  margin-bottom: ${sizes.margin[32]};
  width: fit-content;
  font-size: 32px;
  ${mq.onlysp} {
    margin-bottom: ${sizes.margin[48]};
    font-size: 32px;
  }
`;

const CardLinkArrow = styled('div')`
  position: absolute;
  top: 0;
  right: -40px;
  width: 24px;
  height: 24px;
`;

const CardTxt = styled(Text)`
  > span {
    white-space: normal;
    letter-spacing: -0.03em;
  }
`;

const text = {
  ja: {
    contact:
      'サービスやプロダクトのユーザー体験、また組織や事業のビジョン策定に関するご依頼・ご相談をお待ちしています。',
    Career: '私たちの活動やデザインへの向き合い方に共感いただける方々を求めています。',
  },
  en: {
    contact:
      'We welcome your inquiries and consultations regarding user experience for services and products, as well as the formulation of visions for organizations and businesses.',
    Career: 'We are looking for individuals who resonate with our activities and approach to design.',
  },
} as const;

export const InquiryBlock: React.FC<InquiryBlockProps> = props => {
  const [mouseOnLeft, setmouseOnLeft] = useState(false);
  const [mouseOnRight, setmouseOnRight] = useState(false);
  const lang = props.en ? 'en' : 'ja';

  let isSp = false;
  if (typeof window !== 'undefined') isSp = window.innerWidth <= breakpoint;
  if ('isStaticSp' in props) isSp = !!props.isStaticSp;
  return (
    <Container className={mouseOnLeft ? 'is-hover-left' : mouseOnRight ? 'is-hover-right' : undefined}>
      <Inner>
        <CardList>
          <CardListItem>
            <CardLink
              to={`${props.en ? 'https://www.4digit.com/en/contact/' : 'https://www.4digit.com/contact/'}`}
              onMouseOver={() => !mouseOnLeft && setmouseOnLeft(true)}
              onMouseLeave={() => setmouseOnLeft(false)}
              target="_blank"
              rel="noopener"
            >
              <CardLinkInner>
                <CardHead>
                  Contact
                  <CardLinkArrow>
                    <Arrow hoverAnime />
                  </CardLinkArrow>
                </CardHead>
                <CardTxt>{text[lang].contact}</CardTxt>
              </CardLinkInner>
            </CardLink>
          </CardListItem>
          <CardListItem>
            <CardLinkBlank
              href="https://recruit.4digit.com/"
              target="_blank"
              rel="noopener"
              onMouseOver={() => !mouseOnRight && setmouseOnRight(true)}
              onMouseLeave={() => setmouseOnRight(false)}
            >
              <CardLinkInner>
                <CardHead>
                  Career
                  <CardLinkArrow>
                    <Arrow hoverAnime />
                  </CardLinkArrow>
                </CardHead>
                <CardTxt>{text[lang].Career}</CardTxt>
              </CardLinkInner>
            </CardLinkBlank>
          </CardListItem>
        </CardList>
      </Inner>
    </Container>
  );
};

export default InquiryBlock;
